.slide {
    position: relative;
    border: 2px solid rgb(19, 130, 117);
    padding: 0 20px 20px 20px;
    border-radius: 10px;
    margin-top: 20px;
}

.slide h1,
.slide h2,
.slide h3,
.slide p,
.slide table,
.slide td,
.slide th {
    font-family: 'Noto Sans', sans-serif;
    font-weight: bold;
}

.button {
    position: absolute;
    right: 0;
    top: 15px;
    margin: 10px;
    color: rgba(19,130,117,1);
    /* justify-items: right; */
}

.header-section {
    text-align: center;
    margin-bottom: 20px;
}

.sentence-container {
    display: flex;
    justify-content: space-between;
    gap: .5rem;
    margin-bottom: 20px;
}

.sentence-box {
	width: 50%;
	background: #f0f0f0;
	border: 2px solid rgb(19, 130, 117);
	border-radius: 5px;
	padding: 10px;
    min-height: 3em; /*Prepare spaces for two line text to avoid flickering. */
}

.sentence-box p {
	font-size: 1rem;
    margin: 0;
    line-height: 1.5em;
    font-weight: bold;
}

table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.explainer-th,
.explainer-td {
    border: 2px solid rgb(19, 130, 117);
    padding: 10px;
    text-align: center;
    background: #ffffff;
}

.explainer-th {
    background: rgb(19, 130, 117);
    color: white;
}

.speech {
    display: block;
    margin-top: 30px;
    padding: 20px;
    border: 2px solid rgb(19, 130, 117);
    border-radius: 5px;
    background: #f9f9f9;
}

.step {
    margin: 10px 0;
    cursor: pointer;
    font-weight: bold;
}

.highlighted-part {
    background: yellow;
    font-weight: bold;
}

#input-container {
    margin-bottom: 20px;
}

#json-input {
    width: 100%;
    height: 200px;
}

#render-button {
    margin-top: 10px;
    padding: 10px;
    background: rgb(19, 130, 117);
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
}
.explain-step {
	background-color: #fdfdfd;
	color: #138275;
	text-decoration: none;
	font-weight: 550;
}
.explain-step:hover {
	font-weight: bold;
}